import React from 'react';
import PropTypes from 'prop-types';
import { PlatformToolbar } from '@apex/react-toolkit/components';
import 'bootstrap/dist/css/bootstrap.min.css'

/**
 * Toolbar is the Apex Platform Toolbar. It handles
 * some basic functionality needed by applications.
 */
const Toolbar = ({
  icon,
  name,
  navigationItems,
  statusIcons,
  settingsComponent,
  onFreshUser,
  onFreshToken,
  onLogOut,
  isTesting,
}) => {
  return (
    <PlatformToolbar
      icon={icon}
      name={name}
      navigationItems={navigationItems}
      statusIcons={statusIcons}
      settingsComponent={settingsComponent}
      onFreshUser={() => onFreshUser()}
      onFreshToken={() => onFreshToken()}
      onLogOut={() => onLogOut()}
      isTesting={isTesting}
    />
  );
};

Toolbar.defaultProps = {};

Toolbar.propTypes = {
  icon: PropTypes.node,
  name: PropTypes.string,
  href: PropTypes.string,
  navigationItems: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  statusIcons: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  settingsComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  onFreshUser: PropTypes.func,
  onFreshToken: PropTypes.func,
  onLogOut: PropTypes.func,
  isTesting: PropTypes.bool,
};

export default Toolbar;

export const defaultProps = Toolbar.defaultProps;
export const propTypes = Toolbar.propTypes;

